import { call, put, select, takeLatest } from 'redux-saga/effects';
import { getCategoriesReq } from 'src/services/categoriesService';
import { getTourByIdReq, getToursReq } from 'src/services/ToursService';
import { IRootState } from 'src/types/store-types';
import { SUCCESS_STATUSES } from 'src/utils/constants';
import { removeFromArray } from 'src/utils/helpers';
import { mutateCart, setTourById, setTours } from './actions';
import { GET_CATEGORY_TOURS, GET_SINGLE_TOUR, GET_TOURS, GET_VIP_TOURS, REMOVE_FROM_CARD } from './types';

function* getTourByIdSaga({ payload }: any): any {
  try {
    const res: any = yield call(getTourByIdReq, payload);
    if (SUCCESS_STATUSES.includes(res.status)) {
      yield put(setTourById(res.data));
    }
  } catch {
    // yield put(getCategories.success([]));
  }
}

function* getToursSaga({ payload }: any): any {
  try {
    if (payload.city === 'sharm-el-sheikh') {
      payload.city = 'Sharm El Sheikh';
    }

    const state: IRootState = yield select();
    const tours = yield state.tours.tours;
    const res: any = yield call(getToursReq, payload);
    if (SUCCESS_STATUSES.includes(res.status)) {
      yield put(setTours([...tours, ...res.data]));
    }
  } catch {
    // yield put(getCategories.success([]));
  }
}
// payload is city
function* getVIPToursSaga({ payload }: any): any {
  try {
    const { city, offset, limit } = payload;

    const categoriesRes = yield call(getCategoriesReq, payload.city);
    const categories = categoriesRes.data;
    const filteredCategories = categories.filter((category: any) => category.city === city);
    const vipToursCategory = filteredCategories.filter((category: any) => category.en_name === 'VIP tours');
    const categoryId = vipToursCategory[0].id;
    const _city = vipToursCategory[0].city;

    yield call(getToursSaga, { payload: { city: _city, categoryId, offset, limit } });
  } catch {
    // yield put(getCategories.success([]));
  }
}

function* getToursByCategorySaga({ payload }: any): any {
  const city = payload.city == 'hurghada' || payload.city == 'Hurghada' ? 'Hurghada' : 'Sharm El Sheikh';
  try {
    const res: any = yield call(getToursReq, { city, categoryId: payload.id });

    if (SUCCESS_STATUSES.includes(res.status)) {
      yield put(setTours(res.data));
    }
  } catch {
    // yield put(getCategories.success([]));
  }
}

function* removeFromCartSaga({ payload }: any): any {
  try {
    const state: IRootState = yield select();
    const { cartToursArray } = state.tours;
    const newCartToursArray = removeFromArray(cartToursArray, (tour) => tour.id === payload);

    yield put(mutateCart(newCartToursArray));

    const localStorageToursArr = JSON.parse(localStorage.getItem('cartToursArray') as any);

    for (let i = 0; i < localStorageToursArr.length; i++) {
      localStorageToursArr.splice(i, 1);
      localStorage.removeItem('cartToursArray');
      localStorage.setItem('cartToursArray', JSON.stringify(localStorageToursArr));
    }
  } catch {
    console.log('error');
  }
}

export function* watchTours(): any {
  yield takeLatest(GET_TOURS, getToursSaga);
  yield takeLatest(GET_SINGLE_TOUR, getTourByIdSaga);
  yield takeLatest(GET_VIP_TOURS, getVIPToursSaga);
  yield takeLatest(GET_CATEGORY_TOURS, getToursByCategorySaga);
  yield takeLatest(REMOVE_FROM_CARD, removeFromCartSaga);
}
