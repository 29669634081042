import { createAction } from 'deox';
import {
  GET_ORDER_DATA,
  ORDER_CHECKING,
  ORDER_CHECKOUT,
  RESET_ORDER_DATA,
  SET_ORDER_DATA,
  SET_ORDER_SUCCESS,
} from './types';

export const setOrder = createAction(SET_ORDER_DATA, (res) => (params) => res(params));
export const getOrder = createAction(GET_ORDER_DATA, (res) => (params) => res(params));

export const resetOrderData = createAction(RESET_ORDER_DATA);

export const checkout = createAction(ORDER_CHECKOUT, (res) => (params) => res(params));

export const orderChecking = createAction(ORDER_CHECKING, (res) => (params) => res(params));

export const setOrderSuccess = createAction(SET_ORDER_SUCCESS, (res) => (params) => res(params));
