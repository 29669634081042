import { API_VERSION } from 'src/utils/constants';

import { makeQueryString } from 'src/utils/helpers';
import HttpClient from './http';

export const getToursReq = (payload: any): Promise<any> => {
  return HttpClient.get({
    path: `${API_VERSION}/tours/?${makeQueryString(payload)}&sortByRate=true`,
    baseURL: process.env.REACT_APP_REMOTE as string,
  });
};
export const getTourByIdReq = (id: string): Promise<any> => {
  return HttpClient.get({ path: `${API_VERSION}/tours/${id}`, baseURL: process.env.REACT_APP_REMOTE as string });
};

export const getToursByName = (name: string, lang: string, city: string): Promise<any> => {
  return HttpClient.get({
    path: `${API_VERSION}/tours/search?keyword=${name}&language=${lang}&city=${city}`,
    baseURL: process.env.REACT_APP_REMOTE as string,
  });
};
