import { API_VERSION } from 'src/utils/constants';

import { makeQueryString } from 'src/utils/helpers';
import HttpClient from './http';

export const checkoutReq = (data: any): Promise<any> => {
  return HttpClient.post({ path: `${API_VERSION}/orders`, baseURL: process.env.REACT_APP_REMOTE as string }, data);
};

export const checkConfirm = (data: { orderId: string }): Promise<any> => {
  return HttpClient.post(
    { path: `${API_VERSION}/orders/confirm`, baseURL: process.env.REACT_APP_REMOTE as string },
    data
  );
};

export const getExchangeValue = (payload?: any): Promise<any> => {
  return HttpClient.get({
    path: `${API_VERSION}/payments/exchangeRates${payload ? '?' + makeQueryString(payload) : ''}`,
    baseURL: process.env.REACT_APP_REMOTE as string,
  });
};
