import { createReducer } from 'deox';
import produce from 'immer';
import { getOrder, orderChecking, resetOrderData, setOrder, setOrderSuccess } from './actions';
import { orderState } from './types';

const DEFAULT_ORDER_DATA = {
  name: '',
  email: '',
  contact: 'telegram',
  phoneNumber: '',
  hotel: '',
  room: '',
  city: sessionStorage.city ? JSON.parse(sessionStorage.city) : '',
  firstDate: '',
  lastDate: '',
  paymentMethod: 'cash',
  peopleCount: 1,
  childCount: 0,
  referralLink: sessionStorage.getItem('referralLink') || '',
};

export const orderInitialState: orderState = {
  orderState: DEFAULT_ORDER_DATA,
  isLoading: false,
  orderChecking: false,
  isSuccess: false,
};

export const orderReducer = createReducer(orderInitialState, (handle) => [
  handle(setOrder, (state, { payload }: any) =>
    produce(state, (draft) => {
      draft.orderState = {
        ...state.orderState,
        [payload.name]: payload.value,
      };
    })
  ),
  handle(orderChecking, (state, { payload }: any) =>
    produce(state, (draft) => {
      draft.orderChecking = payload;
    })
  ),
  handle(getOrder, (state, { payload }: any) =>
    produce(state, (draft) => {
      draft.isLoading = payload.isLoading;
    })
  ),

  handle(resetOrderData, (state) =>
    produce(state, (draft) => {
      draft.orderState = { ...DEFAULT_ORDER_DATA, referralLink: state.orderState.referralLink };
    })
  ),
  handle(setOrderSuccess, (state, { payload }: any) =>
    produce(state, (draft) => {
      draft.isSuccess = payload;
    })
  ),
]);
