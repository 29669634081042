/* eslint-disable no-negated-condition */
import { createReducer } from 'deox';
import produce from 'immer';
import { removeFromArray } from 'src/utils/helpers';
import {
  addToCard,
  getOrder,
  removeFromCart,
  resetSingleTour,
  resetStoredCartData,
  resetTours,
  setCartCount,
  setOrder,
  setTourById,
  setTours,
} from './actions';
import { tourState } from './types';

const hasTours = localStorage.cartToursArray;

export const toursInitialState: tourState = {
  tours: [],
  singleTour: null,
  cartToursArray: hasTours ? JSON.parse(localStorage.cartToursArray) : [],
  cartCount: localStorage.cartCount ? +JSON.parse(localStorage.cartCount) : 0,
};

export const tourReducer = createReducer(toursInitialState, (handle) => [
  handle(setOrder, (state, { payload }: any) =>
    produce(state, (draft: any) => {
      draft[payload.name] = payload.value;
    })
  ),
  handle(setCartCount, (state, { payload }: any) =>
    produce(state, (draft) => {
      sessionStorage.setItem('cartCount', JSON.stringify(payload));
      draft.cartCount = payload;
    })
  ),
  handle(getOrder, (state, { payload }: any) =>
    produce(state, (draft: any) => {
      draft.isLoading = payload.isLoading;
    })
  ),
  handle(setTours, (state, { payload }: any) =>
    produce(state, (draft: any) => {
      draft.tours = payload;
    })
  ),
  handle(resetTours, (state) =>
    produce(state, (draft: any) => {
      draft.tours = [];
    })
  ),
  handle(setTourById, (state, { payload }: any) =>
    produce(state, (draft: any) => {
      draft.singleTour = payload;
    })
  ),
  handle(resetSingleTour, (state) =>
    produce(state, (draft: any) => {
      draft.singleTour = null;
    })
  ),
  handle(addToCard, (state, { payload }: any) =>
    produce(state, (draft: any) => {
      const formattedDate = payload.firstDate.replace(/\//g, '-');
      // Ensure the payload is added at the correct position based on date and time
      const payloadDateTime = new Date(`${formattedDate}T${payload.startTime}`);
      let inserted = false;

      // Initialize cartToursArray if not already present
      draft.cartToursArray = Array.isArray(draft.cartToursArray) ? draft.cartToursArray : [];

      // Insert payload in the correct position
      for (let i = 0; i < draft.cartToursArray.length; i++) {
        const currentItem = draft.cartToursArray[i];
        const formattedDate = currentItem.firstDate.replace(/\//g, '-');
        const currentItemDateTime = new Date(`${formattedDate}T${currentItem.startTime}`);

        if (payloadDateTime < currentItemDateTime) {
          draft.cartToursArray.splice(i, 0, payload); // Insert at the correct position
          inserted = true;
          break;
        }
      }

      // If no earlier date is found, push to the end
      if (!inserted) {
        draft.cartToursArray.push(payload);
      }

      // Update localStorage to match the updated draft
      localStorage.setItem('cartToursArray', JSON.stringify(draft.cartToursArray));
      localStorage.setItem('cartCount', JSON.stringify(draft.cartToursArray.length));

      // Update the cart count
      draft.cartCount = draft.cartToursArray.length;
    })
  ),

  handle(removeFromCart, (state, { payload }: any) =>
    produce(state, (draft: any) => {
      const newTours = removeFromArray(state.cartToursArray, (tour: any) => tour.id === payload);
      localStorage.setItem('cartToursArray', JSON.stringify(newTours));
      localStorage.setItem('cartCount', JSON.stringify(+state.cartCount - 1));

      draft.cartToursArray = newTours;
      draft.cartCount = +state.cartCount - 1;
    })
  ),
  handle(resetStoredCartData, (state) =>
    produce(state, (draft: any) => {
      draft.cartToursArray = [];
      draft.cartCount = 0;
    })
  ),
]);
