import { FC, lazy, Suspense, useEffect, useState } from 'react';
import { isMobile, isTablet } from 'react-device-detect';
import { shallowEqual, useSelector } from 'react-redux';
import { Route, Routes, useLocation } from 'react-router-dom';
import { EnumRoutePaths } from 'src/configs/routes';
import { useConnectedAction } from 'src/hooks/use-connected-action';
import { useQuery } from 'src/hooks/useQuery';
import MainLayout from 'src/Layouts/MainLayout';
import WithSliderLayout from 'src/Layouts/WithSliderLayout';
import http from 'src/services/http';
import { setLocation } from 'src/store/app/actions';
import { resetCategories } from 'src/store/categories/actions';
import { setOrder } from 'src/store/order/actions';
import { IRootState } from 'src/types/store-types';
import { API_VERSION } from 'src/utils/constants';
import { Loader } from '../atoms/Loader/Loader';
import SocialIcons from '../common/SocialIcons';
import { CityPopup } from '../popups/CityPopup';

const Tours = lazy(() => import('../pages/Tours'));
const Rates = lazy(() => import('../pages/Rates'));
const TourBookingPage = lazy(() => import('../pages/TourBookingPage'));
const ToursPage = lazy(() => import('../pages/ToursPage'));
const Privacy = lazy(() => import('../pages/Privacy'));
const About = lazy(() => import('../pages/About'));
const CategoriesPage = lazy(() => import('../pages/CategoriesPage'));
const CartPage = lazy(() => import('../pages/CartPage'));
const OrderChecking = lazy(() => import('../pages/OrderChecking'));
const _404_Page = lazy(() => import('../pages/404'));
const Blog = lazy(() => import('../pages/Blog'));
const SingleBlog = lazy(() => import('../pages/SingleBlog'));

const AnonimusRoutes: FC = () => {
  const [visible, setVisible] = useState(false);
  const { cities } = useSelector((state: IRootState) => state.app, shallowEqual);
  const queries = useQuery();

  const setLocationAction = useConnectedAction(setLocation);
  const resetCategoriesAction = useConnectedAction(resetCategories);
  const setOrderDataAction = useConnectedAction(setOrder);

  const handleLoad = (): void => {
    setVisible(true);
  };

  const handleWindowOnLoad = (): void => {
    if (window.location.pathname !== '/') {
      const city = window.location.pathname.split('/')[1];
      if (city == 'hurghada' || city == 'Hurghada') {
        sessionStorage.setItem('city', JSON.stringify('Hurghada'));
        setLocationAction('Hurghada');
      } else if (city == 'Aswan' || city == 'aswan') {
        sessionStorage.setItem('city', JSON.stringify('Aswan'));
        setLocationAction('Aswan');
      } else if (city == 'cairo' || city == 'Cairo') {
        sessionStorage.setItem('city', JSON.stringify('Cairo'));
        setLocationAction('Cairo');
      } else {
        sessionStorage.setItem('city', JSON.stringify('Sharm El Sheikh'));
        setLocationAction('Sharm El Sheikh');
      }
    }
    if (sessionStorage.getItem('city')) {
      setLocationAction(JSON.parse(sessionStorage.getItem('city') as string));
    } else {
      handleLoad();
    }
  };

  const postRerralLinkVisitors = async (referralLink?: string): Promise<void> => {
    let device = '';
    if (isMobile) {
      device = 'mobile';
    } else if (isTablet) {
      device = 'tablet';
    } else {
      device = 'PC';
    }
    http.post(
      { path: `${API_VERSION}/users/visitors`, baseURL: process.env.REACT_APP_REMOTE as string },
      referralLink ? { referralLink, device } : { device }
    );
  };

  // api/v1/users/visitors
  useEffect(() => {
    handleWindowOnLoad();
    sessionStorage.setItem('referralLink', queries.referralLink);
    postRerralLinkVisitors(queries.referralLink || '');
    if (queries.referralLink) {
      setOrderDataAction({ name: 'referralLink', value: queries.referralLink });
    }
  }, [queries]);

  useEffect(() => {
    sessionStorage.setItem('referralLink', queries.referralLink);
    postRerralLinkVisitors(queries.referralLink || sessionStorage.getItem('referralLink') || '');
  }, []);

  const location = useLocation();

  return (
    <MainLayout>
      <section className="app-content-container">
        {!location.pathname.includes('blog') && <SocialIcons color="black" className="static" />}
        <Routes>
          <Route
            path={EnumRoutePaths.PRIVACY}
            element={
              <Suspense fallback={<Loader />}>
                <Privacy />
              </Suspense>
            }
          />
          <Route
            path={EnumRoutePaths.TAXI}
            element={
              <Suspense fallback={<Loader />}>
                <Privacy />
              </Suspense>
            }
          />
          <Route
            path={EnumRoutePaths.ABOUT}
            element={
              <Suspense fallback={<Loader />}>
                <About />
              </Suspense>
            }
          />
          <Route
            path={EnumRoutePaths.CAREGORY_TOUR}
            element={
              <WithSliderLayout>
                <Suspense fallback={<Loader />}>
                  <Tours />
                </Suspense>
              </WithSliderLayout>
            }
          />
          <Route
            path={EnumRoutePaths.CAREGORIES}
            element={
              <WithSliderLayout>
                <Suspense fallback={<Loader />}>
                  <CategoriesPage />
                </Suspense>
              </WithSliderLayout>
            }
          />
          <Route
            path={EnumRoutePaths.VIP_TOURS}
            element={
              <WithSliderLayout>
                <Suspense fallback={<Loader />}>
                  <ToursPage headerName="vipTours" fromAiSuggestions={false} />
                </Suspense>
              </WithSliderLayout>
            }
          />

          <Route
            path={EnumRoutePaths.TOUR_BOOKING}
            element={
              <WithSliderLayout>
                <Suspense fallback={<Loader />}>
                  <TourBookingPage />
                </Suspense>
              </WithSliderLayout>
            }
          />
          <Route
            path={EnumRoutePaths.AI_SUGGESTIONS}
            element={
              <WithSliderLayout>
                <Suspense fallback={<Loader />}>
                  <ToursPage headerName="aiSuggestions" fromAiSuggestions={true} />
                </Suspense>
              </WithSliderLayout>
            }
          />
          <Route
            path={EnumRoutePaths.BASE_1}
            element={
              <WithSliderLayout>
                <Suspense fallback={<Loader />}>
                  <ToursPage headerName="mostPopular" fromAiSuggestions={false} />
                </Suspense>
              </WithSliderLayout>
            }
          />
          <Route
            path={EnumRoutePaths.CART}
            element={
              <Suspense fallback={<Loader />}>
                <CartPage />
              </Suspense>
            }
          />
          <Route
            path={EnumRoutePaths.CHECKING}
            element={
              <Suspense fallback={<Loader />}>
                <OrderChecking />
              </Suspense>
            }
          />
          <Route
            path={EnumRoutePaths.RATES}
            element={
              <Suspense fallback={<Loader />}>
                <Rates />
              </Suspense>
            }
          />
          <Route
            path={EnumRoutePaths.SINGLE_BLOG}
            element={
              <WithSliderLayout isBlog>
                <Suspense fallback={<Loader />}>
                  <SingleBlog />
                </Suspense>
              </WithSliderLayout>
            }
          />
          <Route
            path={EnumRoutePaths.BLOG}
            element={
              <WithSliderLayout isBlog>
                <Suspense fallback={<Loader />}>
                  <Blog />
                </Suspense>
              </WithSliderLayout>
            }
          />
          <Route
            path={EnumRoutePaths.BASE}
            element={
              <WithSliderLayout>
                <Suspense fallback={<Loader />}>
                  <ToursPage headerName="mostPopular" fromAiSuggestions={false} />
                </Suspense>
              </WithSliderLayout>
            }
          />
          <Route
            path="*"
            element={
              <Suspense fallback={<Loader />}>
                <_404_Page headerName="Incorect Route" />
              </Suspense>
            }
          />
        </Routes>
      </section>
      <CityPopup
        visible={visible}
        setVisible={setVisible}
        cities={cities}
        setCity={setLocationAction}
        resetCategories={resetCategoriesAction}
        setOrderData={setOrderDataAction}
      />
    </MainLayout>
  );
};

export default AnonimusRoutes;
