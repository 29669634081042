import { ChangeEvent, FC, useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useSelector } from 'react-redux';
import { Link, useLocation } from 'react-router-dom';

import { Input } from 'antd';
import { SvgIcon } from 'src/components/atoms/SvgIcon';
import { CityPopup } from 'src/components/popups/CityPopup';
import HeaderModal from 'src/components/popups/HeaderModal';
import { useConnectedAction } from 'src/hooks/use-connected-action';
import { getToursByName } from 'src/services/ToursService';
import { resetImagesArr, setLng, setLocation } from 'src/store/app/actions';
import { resetCategories } from 'src/store/categories/actions';
import { setOrder } from 'src/store/order/actions';
import { getTours, setTours } from 'src/store/tours/actions';
import { IRootState } from 'src/types/store-types';
import { capitalizeFirstLetter, makePath, scrollToTop } from 'src/utils/helpers';
import SocialIcons from '../SocialIcons';
// import SearchPopover from './Search';
import './styles.scss';
const { Search } = Input;

const Header: FC = () => {
  const { t, i18n } = useTranslation();
  const resetCategoriesAction = useConnectedAction(resetCategories);
  const resetImagesArrAction = useConnectedAction(resetImagesArr);
  const setLngAction = useConnectedAction(setLng);
  const setLocationAction = useConnectedAction(setLocation);
  const setOrderDataAction = useConnectedAction(setOrder);
  const _setTours = useConnectedAction(setTours);
  const _getTours = useConnectedAction(getTours);

  const _location = useLocation();
  const currentPath = _location.pathname;

  let { lng } = useSelector((state: IRootState) => state.app);
  const { cities, location } = useSelector((state: IRootState) => state.app);
  const { cartCount } = useSelector((state: IRootState) => state.tours);

  lng = lng.toLowerCase();
  const [activetab, setActive] = useState(lng);
  const [visibleModal, setVisibleModal] = useState(false);
  const [width, setWidth] = useState(window.innerWidth);
  const [searchTerm, setSearchTerm] = useState('');

  useEffect(() => {
    const handleResize = (): void => setWidth(window.innerWidth);
    window.addEventListener('resize', handleResize);
    return () => {
      window.removeEventListener('resize', handleResize);
    };
  }, []);
  const [visible, setVisible] = useState(false);
  const handleSelectLng = (lng: string): void => {
    setLngAction(lng);
    i18n.changeLanguage(lng);
    setActive(lng);
  };
  const toogleList = (): void => {
    setVisibleModal((state: boolean) => !state);
  };

  const closeHeaderModal = (): void => {
    if (visibleModal) {
      setVisibleModal(false);
    }
  };

  const onLogoClick = (): void => {
    resetImagesArrAction();
    closeHeaderModal();
    scrollToTop();
  };

  const handleChange = (e: ChangeEvent<HTMLInputElement>): void => {
    setSearchTerm(e.target.value);
  };

  const onSearch = async (): Promise<void> => {
    const res = await getToursByName(searchTerm, lng, location);
    if (res.data?.length) {
      _setTours(res.data);
    }
  };
  const handleClear = (): void => {
    _getTours({ city: location, limit: 6, offset: 0 });
  };

  useEffect(() => {
    return () => {
      setSearchTerm('');
    };
  }, []);

  return (
    <header className="header">
      <div className="header-logo-container">
        <Link to="/" onClick={onLogoClick}>
          <SvgIcon className="logo" src={'/images/logo.svg'} />
        </Link>
      </div>
      {/* <div className="trustPilot-container">
        <div
          className="trustpilot-widget"
          data-locale="en-GB"
          data-template-id="56278e9abfbbba0bdcd568bc"
          data-businessunit-id="6738b7067a770a6694f7566a"
          data-style-height="52px"
          data-style-width="150px"
        >
          <a href="https://uk.trustpilot.com/review/tourest.online" target="_blank" rel="noopener">
            Trustpilot
          </a>
        </div>
      </div> */}
      {[...cities, '/'].map((item) => item.toLowerCase()).includes((currentPath + '11111').toLowerCase()) ? (
        <div>
          <Search
            placeholder={t('searchTour') as string}
            size="large"
            value={searchTerm}
            onChange={handleChange}
            onSearch={(value) => {
              if (value.trim() === '') {
                handleClear(); // Call handleClear when the user clears and presses Enter
              } else {
                onSearch(); // Perform search if there is input
              }
            }}
            allowClear
          />
        </div>
      ) : null}
      <div className="header-rigth-container">
        <div className="header-social-icons-conatiner" style={{ display: width > 800 ? 'flex' : 'none' }}>
          <SocialIcons className="header-social-icons" color="#000" />
        </div>
        {/* <div>
          <SearchPopover />
        </div> */}
        <div
          className="header-content city-container link"
          onClick={() => setVisible(true)}
          style={{ display: width > 800 ? 'flex' : 'none' }}
        >
          <div className="header-content-tab">
            {location ? capitalizeFirstLetter(location) : t('chooseCity')}
            <div className="header-content-border-bottom"></div>
          </div>
        </div>

        <CityPopup
          visible={visible}
          setVisible={setVisible}
          cities={cities}
          setCity={setLocationAction}
          resetCategories={resetCategoriesAction}
          setOrderData={setOrderDataAction}
        />
        <div className="header-content left link" style={{ display: width > 800 ? 'flex' : 'none' }}>
          {/* <div className="header-content-tab" style={{ display: width > 1280 ? 'flex' : 'none' }}>
            <Link className="link" to={`${location}/taxi`}>
              {t('taxi')}
            </Link>
            <div className="header-content-border-bottom"></div>
          </div> */}

          <div className="header-content-tab">
            <Link className="link" to={`${makePath(location)}/blog`}>
              {t('blog')}
            </Link>
            <div className="header-content-border-bottom"></div>
          </div>
          <div className="header-content-tab">
            <Link className="link" to={`${makePath(location)}/rates`}>
              {t('rates')}
            </Link>
            <div className="header-content-border-bottom"></div>
          </div>

          <div className="header-content-tab" style={{ display: width > 800 ? 'flex' : 'none' }}>
            {cartCount > 0 && <div className="badge">{cartCount}</div>}
            <Link className="link" to={`${makePath(location)}/cart`}>
              {t('cart')}
            </Link>
            <div className="header-content-border-bottom"></div>
          </div>
          <div className="header-content language-container link" style={{ display: width > 800 ? 'flex' : 'none' }}>
            <div className={`lng-container`} style={{ display: width > 800 ? 'flex' : 'none' }}>
              <div className="header-content-tab">
                <p onClick={() => handleSelectLng('Eng')} className={activetab.toLowerCase() === 'eng' ? 'active' : ''}>
                  Eng
                </p>
                <div className="header-content-border-bottom"></div>
              </div>
              <div className="border"></div>
              <div className="header-content-tab">
                <p onClick={() => handleSelectLng('Rus')} className={activetab.toLowerCase() === 'rus' ? 'active' : ''}>
                  Рус
                </p>
                <div className="header-content-border-bottom"></div>
              </div>
            </div>
          </div>
        </div>
      </div>
      <div className="header-menu-container link">
        <div className="header-menu-container_content">
          <div className="header-content-tab">
            {cartCount > 0 && <div className="badge">{cartCount}</div>}
            <Link className="link" to={`${makePath(location)}/cart`} onClick={closeHeaderModal}>
              {t('cart')}
            </Link>
            <div className="header-content-border-bottom"></div>
          </div>
          {width > 800 && (
            <>
              <div className="header-content-tab">
                <Link className="link" to={`${makePath(location)}/rates`} onClick={closeHeaderModal}>
                  {t('rates')}
                </Link>
                <div className="header-content-border-bottom"></div>
              </div>
              <div className="header-content-tab">
                <Link className="link" to={`${makePath(location)}/blog`} onClick={closeHeaderModal}>
                  {t('blog')}
                </Link>
                <div className="header-content-border-bottom"></div>
              </div>
            </>
          )}
          <div className="header-menu-icon" onClick={() => toogleList()}>
            <div className="header-icon" style={{ width: 22, height: 30 }}>
              <img src="/images/icons/menu.png" alt="menu" />
            </div>
          </div>
        </div>
      </div>
      {width <= 800 && (
        <HeaderModal
          visibleModal={visibleModal}
          setVisibleModal={setVisibleModal}
          cities={cities}
          setCity={setLocationAction}
          resetCategories={resetCategoriesAction}
          lng={lng}
          setLng={setLngAction}
          setOrderData={setOrderDataAction}
        />
      )}

      <div className="user-icon-container"></div>
    </header>
  );
};

export default Header;
