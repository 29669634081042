import { message } from 'antd';
import { call, put, takeLatest } from 'redux-saga/effects';
import { checkoutReq } from 'src/services/orderService';
import { SUCCESS_STATUSES } from 'src/utils/constants';
import { resetStoredCartData } from '../tours/actions';
import { orderChecking, setOrderSuccess } from './actions';
import { ORDER_CHECKOUT } from './types';

function* checkoutSaga({ payload }: any): any {
  try {
    yield put(orderChecking(true));
    const res: any = yield call(checkoutReq, payload);
    if (SUCCESS_STATUSES.includes(res.status)) {
      if (['completed', 'pending'].includes(res.data.paymentStatus)) {
        yield put(setOrderSuccess(true));

        let text = '';

        if (localStorage.getItem('selectedLanguage') === 'Rus') {
          text = 'Бронирование успешно. Наша служба поддержки свяжется с вами в ближайшее время.';
        } else {
          text = 'Successfully booked. Our support team will contact you shortly.';
        }

        message.success(text, 7);

        localStorage.removeItem('cartCount');
        localStorage.removeItem('cartToursArray');
        yield put(resetStoredCartData());
        yield put(orderChecking(false));
      }
    } else {
      yield put(orderChecking(false));
    }
  } catch {
    yield put(orderChecking(false));
  }
}

export function* watchOrders(): any {
  yield takeLatest(ORDER_CHECKOUT, checkoutSaga);
}
