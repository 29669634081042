import { FC } from 'react';
import Slider from 'src/components/common/Slider';
import Tabs from 'src/components/common/Tabs';

const WithSliderLayout: FC<any> = ({ children, isBlog }) => {
  const isTaxiPage = window.location.pathname.includes('taxi');
  return (
    <>
      <Slider isBlog={isBlog} />
      {!isTaxiPage || (!isBlog && <Tabs />)}
      {children}
    </>
  );
};

export default WithSliderLayout;
