import { FC, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { Link } from 'react-router-dom';
import { SvgIcon } from 'src/components/atoms/SvgIcon';
import { mostPopular } from './Tabs';

type Props = {
  location: string;
};

const ResponsiveTabs: FC<Props> = ({ location }) => {
  const { t } = useTranslation();
  const [_activeHeader, setActiveHeader] = useState(mostPopular);

  const handleTabClick = (key: string): void => {
    setActiveHeader(key);
  };

  return (
    <div className="responsive-tab">
      <div onClick={() => handleTabClick('mostPopular')} key="mostPopular">
        <Link to={'/'}>
          <div className="tab">
            <div
              className="tab-icon"
              style={{
                width: 25,
                height: 25,
                color: '#0072fd',
              }}
            >
              <SvgIcon src="/images/icons/star.svg" />
            </div>
            <span className="tabs-name"> {t('mostPopular')}</span>
          </div>
        </Link>
      </div>
      <div onClick={() => handleTabClick('categories')} key="categories">
        <Link to={`/${location}/categories`}>
          <div className="tab">
            <div
              className="tab-icon"
              style={{
                width: 25,
                height: 25,
                color: '#0072fd',
              }}
            >
              <SvgIcon src="/images/icons/list.svg" />
            </div>
            <span className="tabs-name"> {t('toursByCategory')}</span>
          </div>
        </Link>
      </div>
      <div onClick={() => handleTabClick('vipTours')} key="vipTours">
        <Link to={`/${location}/vip-tours`}>
          <div className="tab">
            <div
              className="tab-icon"
              style={{
                width: 25,
                height: 25,
                color: '#0072fd',
              }}
            >
              <SvgIcon src="/images/icons/crown.svg" />
            </div>
            <span className="tabs-name"> {t('vipTours')}</span>
          </div>
        </Link>
      </div>
    </div>
  );
};

export default ResponsiveTabs;
