/* eslint-disable prefer-rest-params */
/* eslint-disable @typescript-eslint/explicit-function-return-type */
import { useEffect } from 'react';

const Analytics = () => {
  useEffect(() => {
    // Load the Google Analytics script
    const script = document.createElement('script');
    script.async = true;
    script.src = `https://www.googletagmanager.com/gtag/js?id=G-SSLW9RRMSS`;
    document.head.appendChild(script);

    // Initialize Google Analytics
    (window as any).dataLayer = (window as any).dataLayer || [];
    function gtag(a: any, b: any) {
      (window as any).dataLayer.push(a, b);
    }
    gtag('js', new Date());
    gtag('config', 'G-SSLW9RRMSS');
  }, []);

  return null; // This component does not render anything
};

export default Analytics;
