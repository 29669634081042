import { call, put, takeLatest } from 'redux-saga/effects';
import { getCategoriesReq } from 'src/services/categoriesService';
// import { makePath } from 'src/utils/helpers';
import { SUCCESS_STATUSES } from 'src/utils/constants';
import { getCategories } from './actions';
import { GET_CATEGORIES } from './types';

function* setCategoriesSaga({ payload }: any): any {
  try {
    const res: any = yield call(getCategoriesReq, payload);
    if (SUCCESS_STATUSES.includes(res.status)) {
      yield put(getCategories.success(res.data));
    } else {
      yield put(getCategories.success([]));
    }
  } catch {
    yield put(getCategories.success([]));
  }
}

export function* watchCategories(): any {
  yield takeLatest(GET_CATEGORIES, setCategoriesSaga);
}
