import { API_VERSION } from 'src/utils/constants';

import { getCity } from 'src/utils/helpers';
import HttpClient from './http';

export const getCategoriesReq = (city: string): Promise<any> => {
  const _city = getCity(city);
  return HttpClient.get({
    path: `${API_VERSION}/categories?city=${encodeURIComponent(_city)}`,
    baseURL: process.env.REACT_APP_REMOTE as string,
  });
};
